

window.addEventListener('scroll', () => {
    let parentProject =  document.getElementById('js-parallax-project-container');

    if ( parentProject ) {
        let childrenProject = parentProject.querySelectorAll('.js-parallax-project-children');
        parallaxFast(parentProject, childrenProject);
    }

    let parentPeople =  document.getElementById('js-parallax-people-container');

    if ( parentPeople ) {
        let childrenPeople = parentPeople.querySelectorAll('.js-parallax-people-children');
        parallaxSlow(parentPeople, childrenPeople);
    }

    let parentTips =  document.getElementById('js-parallax-tips-container');

    if ( parentTips ) {
        let childrenTips = parentTips.querySelectorAll('.js-parallax-tips-children');
        parallaxTips(parentTips, childrenTips);
    }
}, false);

function parallaxFast (parent, children) {

    for(let i = 0; i < children.length; i++) {
        if ( window.matchMedia('(min-width: 1024px)').matches ) {
            children[i].style.transform = 'translateY(-' + ( (window.pageYOffset - window.innerHeight ) * ( i ) / children.length / 5) + 'px)';
        }
    }
}

function offset(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function parallaxSlow (parent, children) {
    let parentOffset = offset(parent);
    if ( window.matchMedia('(min-width: 1024px)').matches ) {
        for (let i = 0; i < children.length; i++) {
            children[i].style.transform = 'translateY(' + ((window.pageYOffset - parentOffset.top) * (i / 5) / children.length) + 'px)';
        }
    }
}

function parallaxTips (parent, children) {
    let parentOffset = offset(parent);
    if ( window.matchMedia('(min-width: 1024px)').matches ) {
        for (let i = 0; i < children.length; i++) {
            children[i].style.transform = 'translateY(-' + ((window.pageYOffset - parentOffset.top) * (i / 5) / children.length) + 'px)';
        }
    }
}

