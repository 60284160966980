
class Menu {

    static mobileMenu() {
        let burger = document.getElementById('burger'),
            nav    = document.getElementById('main-nav'),
            logo   = document.getElementById('menu-logo'),
            container = document.getElementById('menu-container'),
            mainNavLinks = document.querySelectorAll('.main-nav a');

        burger.addEventListener('click', function(e){
            this.classList.toggle('is-open');
            nav.classList.toggle('is-open');
            logo.classList.toggle('is-open');
            container.classList.toggle('is-open');
        });

        mainNavLinks.forEach(link => {
            link.addEventListener('click', function () {
                if ( window.matchMedia('(max-width: 767px)').matches ) {
                    nav.classList.toggle('is-open');
                    logo.classList.toggle('is-open');
                    burger.classList.toggle('is-open');
                    container.classList.toggle('is-open');
                }
            })
        });
    }

    /**
     * Set menu's height to body's padding top to make space for menu.
     */
    static setHeight() {
        const body = document.body;
        const menu = document.getElementById('menu-container');

        body.style.paddingTop = `${menu.offsetHeight}px`;
    }

    /**
     * Hide and show menu on scrolling.
     */
    static setScrolling() {
        let prevScrollPos = window.pageYOffset;

        function init() {
            let menu = document.getElementById('menu-container');
            let currentScrollPos = window.pageYOffset;

            // if menu's height is bigger than scrolled area start scrolling
            if (menu.offsetHeight / 3 < currentScrollPos) {
                if ( menu.classList.contains('js-menu-centered') ) menu.classList.remove('menu-container--centered');
                menu.classList.add('menu-container--is-scrolled');

                // if menu is far away from page start allow animation
                if (currentScrollPos > (menu.offsetHeight * 2)) {
                    menu.classList.add('menu-container--is-animated');
                } else {
                    menu.classList.remove('menu-container--is-animated');
                }

                // detect scrolling top / bottom and hide / show menu
                if (window.matchMedia('(max-width:767px)').matches) {
                    if (prevScrollPos >= currentScrollPos) {
                        menu.style.top = '0';
                    } else {
                        menu.style.top = `-${menu.offsetHeight + 40}px`; // -40 = minus box-shadow
                    }
                }

            } else {
                if ( menu.classList.contains('js-menu-centered') ) menu.classList.add('menu-container--centered');
                menu.classList.remove('menu-container--is-scrolled');
            }

            prevScrollPos = currentScrollPos;
        }

        // show menu on load
        init();
        window.onscroll = function () {
            // init menu on load
            init();
        };
    }

    /**
     * Set overflow hidden to body if menu is open (ban background-scrolling).
     */
    static listenOpen() {
        const checkbox = document.getElementById('nav-toggle'),
            navList = document.getElementsByClassName('nav__list')[0];

        checkbox.addEventListener('change', function(){

            if (checkbox.checked) {
                bodyScrollLock.disableBodyScroll(navList);
               //document.body.style.overflow = 'hidden';
            } else {
                bodyScrollLock.enableBodyScroll(navList);
               //document.body.style.overflow = '';
            }
        });
    }
}
