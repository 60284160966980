
class Slider {

    /**
     * Init classic tiny slider.
     */
    static init() {
        const sliderEl = document.getElementsByClassName('slider');

        if (sliderEl.length > 0) {
            let slider = tns({
                container: sliderEl[0],
                items: 1,
                slideBy: 'page',
                autoplay: false
            });
        }
    }

    /**
     * Init tiny slider with thumbs.
     * "js-gallery-main" is tiny slider which presents big picture with selected thumb.
     * "js-gallery-thumbs" is tiny slider which presents thumbnails.
     */
    static initWithThumbs() {
        const mainGalleries = document.getElementsByClassName('js-gallery-main'),
            thumbsGalleries = document.getElementsByClassName('js-gallery-thumbs');

        let newMainG, newThumbsG;

        if (mainGalleries && thumbsGalleries && ( mainGalleries.length === thumbsGalleries.length ) ) {
            for (let i = 0; i < mainGalleries.length; ++i) {

                newMainG = tns({
                    loop: false,
                    container: mainGalleries[i],
                    navContainer: thumbsGalleries[i],
                    items: 1,
                    navAsThumbnails: true,
                    mouseDrag: false,
                    controls: false
                });

                newThumbsG = tns({
                    loop: false,
                    container: thumbsGalleries[i],
                    items: 4,
                    mouseDrag: true,
                    nav: false,
                    controls: false
                });
            }
        }
    }
}
