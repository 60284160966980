
class Cookies {

    static setCookie(name, value, days) {
        let d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    static getCookie(name) {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    static init() {
        const cookiesBar = document.getElementsByClassName('js-cookies-bar')[0];

        if (Cookies.getCookie('cookiesAllow') !== '1') {
            cookiesBar.classList.remove('we-have-cookies--approve');
        }

        document.addEventListener('click', function (event) {
            if (!event.target.matches('#js-cookies-btn')) return;
            event.preventDefault();
            cookiesBar.classList.add('we-have-cookies--approve');
            Cookies.setCookie('cookiesAllow', 1, 365);
        }, false);
    }
}

