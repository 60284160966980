
class MsnryPlgn {

    static init() {

        const projectTiles = document.querySelector('.js-project-tiles');
        const peopleTiles = document.querySelector('.js-people-tiles');
        const tipsTiles = document.querySelector('.js-tips-tiles');

        let options = {
            itemSelector: '.tile',
            percentPosition : true
        };

        if ( window.matchMedia('(min-width:768px)').matches ) {
            options = {
                itemSelector: '.tile',
                percentPosition : true,
                columnWidth: 350,
                gutter: 30
            }
        }

        if ( window.matchMedia('(min-width:1024px)').matches ) {
            options = {
                itemSelector: '.tile',
                percentPosition : true,
                columnWidth: 310,
                gutter: 30
            }
        }

        if ( window.matchMedia('(min-width:1280px)').matches ) {
            options = {
                itemSelector: '.tile',
                percentPosition : true,
                columnWidth: 350,
                gutter: 45
            }
        }

        let browserIsIE = navigator.userAgent.toUpperCase().indexOf("TRIDENT/") != -1 || navigator.userAgent.toUpperCase().indexOf("MSIE") != -1;
        if ( browserIsIE ) {
            options = {
                itemSelector: '.tile',
                percentPosition : true,
                columnWidth: 350,
                gutter: 20
            }
        }

        if ( projectTiles ) window.Projectmsnry = new Masonry( projectTiles, options );
        if ( peopleTiles ) window.Peoplemsnry = new Masonry( peopleTiles, options );
        if ( tipsTiles ) window.Tipsmsnry = new Masonry( tipsTiles, options );
    }
}
