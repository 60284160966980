
document.addEventListener('DOMContentLoaded', (event) => {
    /*
    Menu.setHeight();
    Menu.setScrolling();
    Menu.listenOpen();

    Cookies.init();

    // smoothscroll plugin has to be install as npm package
    let scroll = new SmoothScroll('a[data-scroll]', {
        header: '[data-scroll-menu]'
    });


    let doneOnce = false;
    window.addEventListener('scroll', function () {
        let isOnViewPort = isScrolledIntoView.init( document.getElementsByClassName('js-number-count')[0] );

        if (isOnViewPort && !doneOnce) {
            Counter.init(document.getElementsByClassName('js-number-count'));
            doneOnce = true;
        }
    });
    Selects.ChoicesJS();

    Slider.init();

    PhotoSwipePlugin.init();
    PhotoSwipePlugin.presenter();



     */
    Menu.mobileMenu();
    Menu.setHeight();
    Menu.setScrolling();

    // smoothscroll plugin has to be install as npm package
    let scroll = new SmoothScroll('a[data-scroll]', {
        header: '[data-scroll-menu]'
    });

    Cookies.init();

    FormValidation.vivalid();

    MsnryPlgn.init();

    let projectItems = 0;
    let peopleItems = 1;

    if ( window.matchMedia('(min-width:768px)').matches ) {
        projectItems = 1;
        peopleItems = 3;
    }
    if ( window.matchMedia('(min-width:1024px)').matches ) {
        projectItems = 2;
        peopleItems = 5;
    }

    const sectionContact = document.querySelector('.section--contact');
    // if ( peopleJSON.length < peopleItems && peopleJSON.length <= 3 ) sectionContact.style.paddingTop = 0;

    Projects.setItem(projectItems);
    Projects.setBtn(projectItems);
    People.setItem(peopleItems);
    People.setBtn(peopleItems);
    Tips.setItem(projectItems);
    Tips.setBtn(projectItems);

    PhotoSwipePlugin.init();

    objectFitImages();
});
