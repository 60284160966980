let editablePeopleArray;
if (typeof peopleJSON !== 'undefined') {
    editablePeopleArray = peopleJSON;
}

class People {

    static setItem(number) {
        const container = document.querySelector('.js-people-tiles');

        if (editablePeopleArray && editablePeopleArray.length > 0 && container) {

            for (let i = 0; i <= number; i++) {
                let currentItem = editablePeopleArray[i];

                // set content with currentItem's values
                if (currentItem) {
                    let content = `<span class="tile__body">
                            <span class="tile__header">
                                <img src="${currentItem.img}" alt="Pomegranate" class="tile__img"/>
                            </span>
                            <span class="tile__footer">
                                <span class="tile__title">${currentItem.title}</span>
                                <span class="tile__text">${currentItem.text}</span>
                                <span class="tile__more">Zjistit více</span>
                            </span>
                        </span>`;

                    // create new item as a node element and set its class, href attribute and content
                    let element = document.createElement('a');
                    element.classList.add('tile');
                    element.classList.add('js-parallax-people-children');
                    element.setAttribute('href', currentItem.link);
                    element.innerHTML = content;

                    // only works when new added item is added as fragment...
                    let fragment = document.createDocumentFragment();
                    fragment.appendChild(element);
                    // add new item into DOM
                    container.appendChild(fragment);
                    // append new item by Masonry
                    window.Peoplemsnry.appended(element);
                }
            }

            // remove new item from array
            for (let i = 0; i <= number; i++) {
                editablePeopleArray.shift();
            }

            People.hideBtn();
        }
    }

    static setBtn(number) {
        const btn = document.querySelector('.js-people-show-more');
        let numberForItems = number;

        if (btn) {
            btn.addEventListener('click', function (e) {
                e.preventDefault();
                People.setItem(numberForItems);
            });
        }
    }

    static hideBtn() {
        const btn = document.querySelector('.js-people-show-more');
        const sectionTips = document.querySelector('.section--tips');

        if (btn) {
            if (editablePeopleArray.length === 0) {
                btn.style.display = 'none';
                sectionTips.classList.add('section--tips-no-btn')
            } else {
                sectionTips.classList.add('section--tips-btn')
            }
        }
    }
}
