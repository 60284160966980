
let editableTipsArray;
if (typeof tipsJSON !== 'undefined') {
    editableTipsArray = tipsJSON;
}

class Tips {

    static setItem(number) {
        const container = document.querySelector('.js-tips-tiles');

        if (editableTipsArray && editableTipsArray.length > 0 && container) {

            for (let i = 0; i <= number; i++) {
                let currentItem = editableTipsArray[i];

                // test if array contains item with this index
                if (currentItem) {
                    let labelStyle = '';
                    if ( currentItem.label === false ) labelStyle = 'display:none';

                    // set content with currentItem's values
                    let content = `<span class="tile__body">
                                <span class="tile__header">
                                    <img src="${currentItem.img}" alt="Pomegranate" class="tile__img"/>
                                    <span class="tile__label" style="${labelStyle}">${currentItem.label}</span>
                                </span>
                                <span class="tile__footer">
                                    <span class="tile__title">${currentItem.title}</span>
                                    <span class="tile__more">Zjistit více</span>
                                </span>
                            </span>`;

                    // create new item as a node element and set its class, href attribute and content
                    let element = document.createElement('a');
                    element.classList.add('tile');
                    element.classList.add('js-parallax-tips-children');
                    element.setAttribute('href', currentItem.link);
                    element.innerHTML = content;

                    // only works when new added item is added as fragment...
                    let fragment = document.createDocumentFragment();
                    fragment.appendChild(element);
                    // add new item into DOM
                    container.appendChild(fragment);
                    // append new item by Masonry
                    window.Tipsmsnry.appended(element);
                }
            }

            // remove new item from array
            for (let i = 0; i <= number; i++) {
                editableTipsArray.shift();
            }

            Tips.hideBtn();
        }
    }

    static setBtn(number) {
        const btn = document.querySelector('.js-tips-show-more');
        let numberForItems = number;

        if (btn) {
            btn.addEventListener('click', function (e) {
                e.preventDefault();
                Tips.setItem(numberForItems);
            });
        }
    }

    static hideBtn() {
        const btn = document.querySelector('.js-tips-show-more');
        const sectionContact = document.querySelector('.section--contact');

        if (btn) {
            if (editableTipsArray.length === 0) btn.style.display = 'none';
        } else {
            //sectionContact.classList.add('section--contact-btn');
        }

        if (sectionContact) {
            //if ( editableTipsArray.length === 0 && tipsJSON.length > 3) sectionContact.classList.add('section--contact-no-btn');
        }
    }
}
