
class FormValidation {

    static vivalid() {
        // http://www.pazams.com/vivalid/documentation/
        const addCallback = vivalid.htmlInterface.addCallback;
        const initAllValidations = vivalid.htmlInterface.initAll;

        // required, submit form on validation success
        addCallback('onValidationSuccess', function () {
            const form = this.closest('form');
            form.submit();
        });

        // required, do something on validation failure (can use property - invalid, pending, valid)
        addCallback('onValidationFailure', function (invalid, pending, valid) {
            // console.log(invalid, pending, valid); // number of invalid, pending and valid inputs
        });

        // used to display custom UI
        addCallback('onInputValidationResult', function(el, validationsResult, validatorName, stateEnum) {
            let msgEl = el.nextElementSibling;

            if (validationsResult.stateEnum === stateEnum.invalid) {
                el.classList.add('form-input--error');
                msgEl.classList.add('form-error--is-active');
                msgEl.innerHTML = validationsResult.message;
            } else {
                el.classList.remove('form-input--error');
                msgEl.classList.remove('form-error--is-active');
                msgEl.innerHTML = '';
            }
        });

        initAllValidations();
    }

    static showPsw() {
        const showPwsEls = document.getElementsByClassName('js-show-psw');
        let inputPsw;

        if (showPwsEls) {
            for (let i = 0; i < showPwsEls.length; ++i) {
                let formContainer = showPwsEls[i].closest('.form-container');
                if (formContainer) inputPsw = formContainer.getElementsByClassName('js-psw-input')[0];

                if (inputPsw) {
                    showPwsEls[i].addEventListener('mousedown', function(){
                        inputPsw.type = 'text';
                    });
                    showPwsEls[i].addEventListener('touchstart', function(){
                        inputPsw.type = 'text';
                    });
                    showPwsEls[i].addEventListener('mouseup', function(){
                        inputPsw.type = 'password';
                    });
                    showPwsEls[i].addEventListener('touchend', function(){
                        inputPsw.type = 'password';
                    });
                }
            }
        }
    }
}
